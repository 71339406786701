import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import "./Order.css";

const Order = ({ order, type }) => {
  const history = useHistory();

  useEffect(() => {});

  const orderDetail = (order) => {
    history.push({
      pathname: `/orders/${order.id}`,
      order: order,
    });
  };

  const printTicket = (order) => {
    history.push({
      pathname: `/print`,
      order: order,
    });
  };

  return (
    <>
      {type !== undefined && type === "myOrders" ? (
        <tr
          onClick={() => {
            orderDetail(order);
          }}
        >
          <td className="text-center">{order.id}</td>
          <td className="text-center">{getTime(order.datetime)}</td>
          <td className="text-center">{order.totalAmount}</td>
          <td className="text-center">{order.points}</td>
        </tr>
      ) : (
        <tr
          className={
            order.status === "Rebuda"
              ? "text-done"
              : order.status === "Pendent" && order.paymentType !== "Barra"
              ? "text-error"
              : "text-simple"
          }
        >
          <td
            className="text-center"
            onClick={() => {
              orderDetail(order);
            }}
          >
            {order.id}
          </td>
          <td
            className="text-center"
            onClick={() => {
              orderDetail(order);
            }}
          >
            {order.code}
          </td>
          <td
            className="text-center"
            onClick={() => {
              orderDetail(order);
            }}
          >
            {getTime(order.datetime)}
          </td>
          <td
            className="text-center"
            onClick={() => {
              orderDetail(order);
            }}
          >
            {order.status}
          </td>
          {order.status === "Pagat" ||
          order.status === "Rebuda" ||
          (order.status === "Pendent" && order.paymentType === "Barra") ? (
            <td className="ticket-button">
              <Button size="sm" onClick={() => printTicket(order)}>
                TICKET
              </Button>
            </td>
          ) : (
            <td />
          )}
        </tr>
      )}
    </>
  );
};

const getTime = (datetime) => {
  const newDate = new Date(datetime);
  const minutes = newDate.getMinutes();
  const hours = newDate.getHours();
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear().toString().slice(-2);

  return `${date < 10 ? `0${date}` : `${date}`}/${
    month < 10 ? `0${month}` : `${month}`
  }/${year} ${hours < 10 ? `0${hours}` : `${hours}`}:${
    minutes < 10 ? `0${minutes}` : `${minutes}`
  }`;
};

export default Order;
