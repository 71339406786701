import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import Order from "../components/Order";
import { getOrders as listOrders } from "../redux/actions/orderActions";
import "./Orders.css";
import jwt from "jsonwebtoken";
import axios from "axios";
import { format } from "date-fns";

const Orders = () => {
  const history = useHistory();
  const token = jwt.decode(localStorage.getItem("authToken"));
  const [data, setData] = useState([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const getOrders = useSelector((state) => state.getOrders);
  const { orders, loading, error } = getOrders;
  const { store } = location.state !== undefined ? location.state : {};

  const today = () => {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return (
      year +
      "-" +
      ("0" + month).slice(-2) +
      "-" +
      ("0" + day).slice(-2) +
      "T00:00:00.000Z"
    );
  };

  const isAuthorized = () => {
    const authorizeduser =
      (token && (token.role === "worker" || token.role === "manager")) === true;
    return authorizeduser;
  };

  const handleTime = async () => {
    if (isAuthorized()) {
      const fetchOrders = async () => {
        let response = await fetchOrdersCall();
        setData(response.data);
      };

      fetchOrders();
    }
  };

  const fetchOrdersCall = async () => {
    let apiReturn = await axios
      .post("/api/orders/request", {
        datetime: {
          $gt: today(),
        },
        store: store,
        status: ["Pagat", "Rebuda", "Pendent", "Rebutjat"],
      })
      .then(async function (response) {
        return response;
      })
      .catch(function (error) {
        console.error(error);
      });
    return apiReturn;
  };

  useEffect(() => {
    if (!token || (token.role !== "worker" && token.role !== "manager")) {
      history.push("/login");
    }
  }, []);

  useEffect(() => {
    const fromDate = today();

    dispatch(
      listOrders({
        store,
        fromDate,
        status: ["Pagat", "Rebuda", "Pendent", "Rebutjat"],
      })
    );
    const timerId = setTimeout(handleTime, 20000);
    return () => clearTimeout(timerId);
  }, [dispatch, store, data]);

  return (
    <div>
      <table className="table">
        <thead className="thead-dark">
          <tr>
            <th className="text-center">Número</th>
            <th className="text-center">Taula</th>
            <th className="text-center">Hora</th>
            <th className="text-center">Estat</th>
          </tr>
        </thead>
        <tbody>
          <tr className="text-center">
            <td colspan="5">
              {`Última actualització: ` + format(new Date(), "kk:mm:ss")}
            </td>
          </tr>
          {loading ? (
            <tr>
              <td>
                <div className="spinner-border m-5" role="status">
                  <span className="sr-only" />
                </div>
              </td>
            </tr>
          ) : error ? (
            <tr>
              <td>{error}</td>
            </tr>
          ) : (
            orders.map((order) => <Order key={order._id} order={order} />)
          )}
        </tbody>
      </table>
      <div className="text-center">
        {orders !== undefined && orders.length < 1 && !loading
          ? "No hi ha resultats"
          : ""}
      </div>
    </div>
  );
};

export default Orders;
